import React from 'react'
import styled, { keyframes } from 'styled-components'
import Theme from "../utils/theme"

import NavBar from "../components/organisms/navbar"

const Wrapper = styled.div`
	background-color: ${props => props.theme.colors.light};
	height: 100vh;
	min-height: -webkit-fill-available;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: ${props => props.theme.fontSizes.fs_10};
	color: ${props => props.theme.colors.dark};
	overflow:	hidden;

	@supports (-webkit-appearance:none) {
		.os-android & {
			min-height: calc(100vh - 56px);
		}
  }
`

const Picture = styled.picture`
	height: 35vmin;
	width: 35vmin;
	pointer-events: none;

	@media (max-width: ${props => props.theme.breakpoint.sm}) {
		height: 35vmax;
		width: 35vmax;
	}
`

const Logo = styled.img`
	height: 35vmin;
	width: 35vmin;
	pointer-events: none;

	@media (max-width: ${props => props.theme.breakpoint.sm}) {
		height: 35vmax;
		width: 35vmax;
	}
`

const Background = styled.div`
	position: absolute;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

// -----------------------------------------------------

const BottomBackground = styled.div`
	width: 100vw;
	height: 40vh;
	background-color: ${props => props.theme.colors.lignt_darken};
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding-top: 56px;
`

const TextColorBlock = styled.div`
	background-color: ${props => props.theme.colors.primary_darken};
	padding: 4px 16px;
	font-weight: 600;
`

// -----------------------------------------------------

const SearchBlock = styled.div`
	position: absolute;
	top: calc(60% - 82px);
	width: 500px;
	height: 50px;
	background-color: ${props => props.theme.colors.white};
	border: 1px solid ${props => props.theme.colors.dark_25};
	border-radius: 10px;
	display: flex;
	align-items: center;
	padding: 0 16px 0 24px;
	justify-content: space-between;

	@media (max-width: ${props => props.theme.breakpoint.sm}) {
		width: 258px;
		padding: 0 8px 0 16px;
	}
`

const SecureLogo = styled.img`
	margin-right: 8px;

	@media (max-width: ${props => props.theme.breakpoint.sm}) {
		margin-right: 16px;
	}
`

const SecureText = styled.span`
	margin-right: 24px;
	color: #219653;
	font-weight: 500;

	@media (max-width: ${props => props.theme.breakpoint.sm}) {
		display: none;
	}
`

const Pipe = styled.div`
	height: 64%;
	width: 1px;
	background-color: ${props => props.theme.colors.dark_25};
	margin-right: 24px;

	@media (max-width: ${props => props.theme.breakpoint.sm}) {
		margin-right: 8px;
	}
`

const typing = keyframes`
  0% {
    width: 0;
  }
  60% {
    width: 100%
  }
	80% {
    width: 100%;
  }
	98% {
    width: 0;
  }
	100% {
    width: 0;
  }
`

const blinkCaret = keyframes`
  100% {
    border-color: transparent;
	}
	50% {
    border-color: #262626;
  }
	0% {
    border-color: transparent;
  }
`

const Typing = styled.span`
	text-align: left;
	border-right: 4px solid ${props => props.theme.colors.dark_25};
	overflow: hidden;
	white-space: nowrap;
	padding-right: 8px;
	line-height: ${props => props.theme.fontSizes.fs_16};
	animation:
    ${typing} 5s steps(30, end) infinite,
    ${blinkCaret} .5s step-end infinite;
`

const Left = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
`

const Right = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: flex-end;
`

// const Footer = styled.div`
// 	position: absolute;
// 	top: calc(94%);
// 	font-weight: 300;
// 	font-size: 16px;
// 	color: ${props => props.theme.colors.dark_25};
// `

const H1 = styled.h1`
	position: absolute;
	top: 0;
	opacity: 0;
`

const StarLogo = styled.img``

const LandingPage = () => {
	return (
		<Theme classname="os-android">
			<Wrapper>
				<NavBar />
				<H1>TMRoom - Mr.Sitthichai Saejia</H1>
				<Background>
					<Picture>
						<source srcset='/img/TMRoom_logo.webp' type="image/webp" />
						<Logo src='/img/TMRoom_logo.png' alt="logo" />
					</Picture>
					<BottomBackground>
						<TextColorBlock>COMING SOON</TextColorBlock>
					</BottomBackground>
				</Background>
				<SearchBlock>
					<Left>
						<SecureLogo src='/img/secure.svg' alt="secure" />
						<SecureText>ปลอดภัย</SecureText>
						<Pipe />
						<Typing>TUEYTOMA.IN.TH</Typing>
					</Left>
					<Right>
						<StarLogo src='/img/star.svg' alt="star" />
					</Right>
				</SearchBlock>
				{/* <Footer>Mr.Siithichai Saejia (Tuey) - TMRoom</Footer> */}
			</Wrapper>
		</Theme>
	)
}

export default LandingPage
