import React from 'react'
import { createGlobalStyle } from 'styled-components'

// import MockupPage from'./pages/mockupPage'
import LandingPage from'./pages/landingPage'

const GlobalStyle = createGlobalStyle`
	body {
		margin: 0;
		font-family: -apple-system, BlinkMacSystemFont, 'Mitr', 'Segoe UI', 'Roboto', 'Oxygen',
			'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
			sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
`

const App = () => {
	return (
		<div>
			<GlobalStyle />
			<LandingPage />
		</div>
	)
}

export default App
