import React from 'react'
import styled from 'styled-components'
import Theme from "../../utils/theme";

import EmailLogo from "../atoms/email_logo"
import GithubLogo from "../atoms/github_logo"
import LinkedInLogo from "../atoms/linkedin_logo"

const Wrapper = styled.div`
	background-color: ${props => props.theme.colors.lignt_darken};
	height: 60px;
	width: calc(80vw);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-size: ${props => props.theme.fontSizes.fs_10};
	color: ${props => props.theme.colors.dark};
	position: fixed;
	top: 0;
	padding: 0 20vw;
`

const Left = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
`

const Right = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`

const Logo = styled.div`
	font-weight: 500;
	color: ${props => props.theme.colors.dark};
	background-color: ${props => props.theme.colors.primary};
	padding: 2px 12px;
`

const Title = styled.span`
	font-weight: 500;
	color: ${props => props.theme.colors.dark_75};
	padding: 2px 12px;

	@media (max-width: ${props => props.theme.breakpoint.sm}) {
		display: none;
	}
`

const HyperLink = styled.a`
	text-decoration-line: none;
	display: flex;
	flex-direction: row;
	margin-left: 16px;

	.change-color-bg {
		transition: all .2s linear;
	}

	.change-color-text {
		transition: all .2s linear;
	}

	&:hover {
		cursor: pointer;
		transition: ease-in-out .6s;

		.change-color-bg {
			background-color: ${props => props.theme.colors.primary_hover};
			transition: all .2s linear;
		}

		.change-color-text {
			color: ${props => props.theme.colors.primary_hover};
			transition: all .2s linear;
		}
	}

	&:first-child {
		margin-left: 0;
	}
`

const Blank = styled.span`
	opacity: 0;
`

const NavBar = () => {
	return (
		<Theme>
			<Wrapper>
				<Left>
					<HyperLink href="/">
						<Logo className="change-color-bg">SS</Logo>
						<Title className="change-color-text">TMRoom Website</Title>
					</HyperLink>
				</Left>
				<Right>
					<HyperLink href="mailto:tuey.sitthichai@gmail.com" rel="noopener" target="_blank"><Blank>.</Blank><EmailLogo /></HyperLink>
					<HyperLink href="https://github.com/tueytoma" rel="noopener" target="_blank"><Blank>.</Blank><GithubLogo /></HyperLink>
					<HyperLink href="https://www.linkedin.com/in/sitthichai-saejia-8805a0142/" rel="noopener" target="_blank"><Blank>.</Blank><LinkedInLogo /></HyperLink>
				</Right>
			</Wrapper>
		</Theme>
	)
}

export default NavBar
