import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
		primary_darken: "#FDBE31",
		primary_hover: "#FFA25C",
    primary: "#F5CA57",
    primary_75: "#F8D781",
    primary_50: "#FAE4AB",
    primary_25: "#FCF2D5",

    dark: "#262626",
    dark_75: "#5C5C5C",
    dark_50: "#929292",
    dark_25: "#C9C9C9",

    lignt_darken: "#F0F0F0",
    light: "#F9F9F9",
    light_75: "#FBFBFB",
    light_50: "#FCFCFC",
		light_25: "#FDFDFD",

		white: "#FFFFFF"
  },
  fonts: ["Mitr", "sans-serif", "Roboto"],
  fontSizes: {
    fs_10: "calc(10px + 2vmin);",
    fs_16: "calc(16px + 2vmin);",
	},
	breakpoint: {
		sm: "600px",
		md: "900px",
		lg: "1100px"
	}

};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
