import React from 'react'
import styled from 'styled-components'

const SVG = styled.svg`
	path {
		fill: #C4C4C4;
		transition: all .2s linear;
	}

	&:hover {
		cursor:pointer;

		path {
			fill: #DD4B39;
			transition: all .2s linear;
		}
	}
`

const EmailLogo = () => {
	return (
		<SVG width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M22.6005 6.05465C22.2843 6.27782 21.331 6.9406 19.7406 8.04267C18.1502 9.14475 16.9318 9.99331 16.0854 10.5884C15.9925 10.6537 15.7949 10.7955 15.4927 11.014C15.1904 11.2327 14.9393 11.4095 14.7394 11.5443C14.5394 11.6791 14.2976 11.8302 14.0138 11.9976C13.7301 12.1648 13.4627 12.2906 13.2116 12.3739C12.9604 12.4578 12.7279 12.4994 12.514 12.4994H12.5H12.4859C12.2721 12.4994 12.0395 12.4578 11.7883 12.3739C11.5373 12.2906 11.2696 12.1647 10.9862 11.9976C10.7026 11.8301 10.4608 11.679 10.2608 11.5443C10.0608 11.4095 9.80983 11.2327 9.50746 11.014C9.20529 10.7953 9.00749 10.6537 8.9145 10.5884C8.05899 9.99331 5.88245 8.48191 2.38557 6.05436C1.70666 5.58024 1.13945 5.00814 0.683793 4.33846C0.227797 3.66908 4.23454e-05 2.96686 4.23454e-05 2.23219C4.23454e-05 1.61827 0.221053 1.09274 0.662879 0.655653C1.10466 0.21847 1.62789 0 2.23224 0H22.768C23.4841 0 24.0351 0.241778 24.4211 0.725333C24.807 1.20899 25 1.81353 25 2.53891C25 3.12483 24.7441 3.75977 24.2327 4.44332C23.7213 5.12693 23.177 5.66409 22.6005 6.05465Z" fill="#C4C4C4"/>
			<path d="M1.39531 7.5473C4.44557 9.61184 6.76171 11.2163 8.3426 12.3604C8.87273 12.7509 9.30277 13.0557 9.63304 13.2741C9.96331 13.4927 10.4025 13.7159 10.9514 13.9437C11.5 14.1718 12.0113 14.2855 12.4858 14.2855H12.5H12.514C12.9883 14.2855 13.4999 14.1718 14.0486 13.9437C14.5972 13.7159 15.0367 13.4927 15.3669 13.2741C15.697 13.0557 16.1272 12.7509 16.6573 12.3604C17.9129 11.4397 20.2241 9.83514 23.5908 7.5473C24.1211 7.19411 24.5908 6.78927 25 6.33362V17.4103C25 18.0245 24.7815 18.5497 24.3443 18.9869C23.9073 19.4242 23.3817 19.6427 22.7678 19.6427H2.23205C1.61832 19.6427 1.09279 19.4242 0.655654 18.9869C0.218373 18.5496 7.59511e-07 18.0246 7.59511e-07 17.4103V6.33362C0.399999 6.77984 0.86489 7.18468 1.39531 7.5473Z" fill="#C4C4C4"/>
		</SVG>
	)
}

export default EmailLogo
